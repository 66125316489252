<template>
  <div id="page-SPAR">

    <b-card>
      <v-tabs background-color="white">
        <v-tab>SPAR</v-tab>
        <v-tab>{{$t('COMMON.SETTINGS')}}</v-tab>

        <v-tab-item class="py-8">
          <SPARJobTable
            ref="sparJobTable"
          />
        </v-tab-item>
        <v-tab-item class="py-8">
          
        </v-tab-item>
    </v-tabs>
    </b-card>

    
  </div>
</template>


<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import SPARJobTable from '@/view/pages/ml/spar/SPARJobTable.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'spar',
  components: {
    SPARJobTable
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'reg_only']),
  },
  mounted() {
    const loader = this.$loading.show();


    loader && loader.hide();
  },
  data() {
    return {

    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {

      }
    },
  },
  methods: {

    onSettingsUpdated(cols) {
      this.$refs['duplicatesTable'].reload();
    },

    scrollToEditDuplicate() {
      var that = this;
      setTimeout(function() {
        const el = that.$el.getElementsByClassName('scroll-to-container')[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },
    
  }
};
</script>
